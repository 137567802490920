<template>
  <div class="login">
    <h1 class="text-blue">{{ $t('auth.welcome') }}</h1>

    <tabs :options="userTypes" v-model="userType"></tabs>
    <form method="post" v-on:submit.prevent="login" name="login">
      <div class="form-group with-icon-left">
        <div class="input-group">
          <input type="email" id="email" v-model="username" required />
          <i class="fa fa-at icon-left input-icon"></i>
          <label class="control-label" for="email">{{
            $t('auth.email')
          }}</label>
          <i class="bar"></i>
        </div>
      </div>
      <div class="form-group with-icon-left">
        <div class="input-group">
          <input type="password" id="password" v-model="password" required />
          <i class="fa fa-user-secret icon-left input-icon"></i>
          <label class="control-label" for="password">{{
            $t('auth.password')
          }}</label>
          <i class="bar"></i>
        </div>
      </div>

      <div class="d-flex align--center justify--center">
        <button
          type="submit"
          class="btn btn-primary btn-sm btn-with-icon-sm btn-block"
        >
          <div class="btn-with-icon-content">
            <i class="fa fa-sign-in"></i>
            {{ $t('auth.login') }}
          </div>
        </button>
      </div>

      <div class="forget-section" v-if="userType === 'user'">
        <router-link class="link-forget" :to="{ name: 'forget-password' }">
          {{ $t('auth.forget-password') }}?
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Tabs from '../../../elements/Tabs.vue'
import userRoutes from '../../../router/routes/user.routes'
import agentRoutes from '../../../router/routes/agent.routes'

const routes = {
  user: userRoutes,
  agent: agentRoutes
}

export default {
  name: 'login',
  components: { Tabs },
  data() {
    return {
      userType: 'user',
      userTypes: [
        {
          title: 'admin',
          value: 'user'
        },
        {
          title: 'agent',
          value: 'agent'
        }
      ],
      username: '',
      password: ''
    }
  },
  computed: {
    ...mapGetters(['isLoading'])
  },
  methods: {
    ...mapActions([
      'userLogin',
      'agentLogin',
      'fetchCurrentUser',
      'fetchCurrentAgent'
    ]),
    async login() {
      const loginFc =
        this.userType === 'user' ? this.userLogin : this.agentLogin
      const res = await loginFc({
        userType: this.userType,
        username: this.username,
        password: this.password
      })

      if (!!res) {
        this.$router.addRoutes(routes[this.userType])
        localStorage.setItem('userType', this.userType)
        switch (this.userType) {
          case 'user':
            this.fetchCurrentUser()
            if (res.organizations.length > 1) {
              location.href = '/'
            } else {
              location.href = '/'
            }
            break
          case 'agent':
            this.fetchCurrentAgent()
            location.href = '/'
            break
        }
      }
    }
  }
}
</script>

<style lang="scss">
.login {
  @include media-breakpoint-down(md) {
    width: 100%;
    padding-right: 2rem;
    padding-left: 2rem;
    .down-container {
      display: none;
    }
  }

  h1 {
    text-align: center;
    margin-top: 0;
    font-weight: bold;
    font-size: 4rem;
    color: #2f8fcf;
  }
  width: 21.375rem;

  .down-container {
    margin-top: 3.125rem;
  }
}
.forget-section {
  text-align: center;
  font-size: 13px;
  margin-top: 20px;
  a {
    color: #495057;
  }
}
</style>
